import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface ToastI {
  title: string;
  message: string;
  show?: boolean;
  type?: 'success' | 'error';
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor() {}

  private toastSubject = new Subject<ToastI>();

  add({message, title, type = 'success', show = true}: ToastI) {
    this.toastSubject.next({message, title, type, show});
  }

  state() {
    return this.toastSubject.asObservable();
  }
}
