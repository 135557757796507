import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';

export const agencyAuthGuard: CanActivateFn = (route, state) => {
  const router: Router = inject(Router);

  const isLogged = inject(AuthService).isAgency();

  if (state.url == '/agency/login' || state.url == '/agency/register') {
    return true;
  }

  if (!isLogged) {
    localStorage.setItem('ulrToRedirect', state.url);
    router.navigate(['/agency/login']);
  }

  return isLogged;
};
