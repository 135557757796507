import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

export const adminAuthGuard: CanActivateFn = (route, state) => {
  const router: Router = inject(Router);

  const isLogged = inject(AuthService).isAdmin();

  if (state.url == '/admin/login') {
    return true;
  }

  if (!isLogged) {
    localStorage.setItem('ulrToRedirect', state.url);
    router.navigate(['/admin/login']);
  }

  return isLogged;
};
