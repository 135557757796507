import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExternalService } from '../api/external.service';
import { Marketplace, Products } from '../models';
import { ActivatedRoute } from '@angular/router';
import { ProductCardComponent } from '../components/product-card/product-card.component';

@Component({
  selector: 'app-product-preview',
  standalone: true,
  imports: [CommonModule, ProductCardComponent],
  templateUrl: './product-preview.component.html',
  styleUrl: './product-preview.component.scss'
})
export class ProductPreviewComponent {
  constructor(private externalApi: ExternalService, private activatedRoute: ActivatedRoute){}
  marketplacedata: Marketplace | undefined;
  product: Products | undefined;
  ngOnInit(){
    this.activatedRoute.params.subscribe((params) => {
      this.fetchProd(params['id'])
      if (!params['id']) {
        // this.router.navigate(['/']);
        
      }
    })
    this.externalApi.marketplaceData().subscribe(res=>{
      this.externalApi.setCurrency(res.currencyCode);
      this.marketplacedata = res
    })
  }

  fetchProd(id: string) {
    this.externalApi.product(id).subscribe((res) => {
      this.product = res;
    },(err)=>{
      // this.closeModal()
    });
  }
   
  redirect(){
    const link = window.location.origin + `?productId=${this.product?.id}`
    
    window.open(link, '_blank');
  }
  
}
