<div class="fixed right-3 bottom-3 z-[99999]">
@for (toast of toasts; track $index) {
<div
*ngIf="toast.show"
 [ngClass]="[
    toast.type == 'success'
      ? 'border-signals-green bg-[#F1F8F4]'
      : 'border-signals-red bg-[#FAEFEB]'
  ]" class="border rounded-xl p-5 flex flex-row gap-2 max-w-[400px] w-[400px] mb-3">
    <div class="w-[32px]">
        @if(toast.type == 'success'){
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path
                d="M19.9999 13.3331L14.6666 18.6664L11.9999 15.9997M17.6614 4.61172L19.2893 5.99905C19.6995 6.34857 20.2087 6.55988 20.7459 6.60274L22.8779 6.77266C24.1315 6.87269 25.1271 7.86773 25.2272 9.12124L25.3967 11.2536C25.4395 11.7908 25.6516 12.3008 26.0011 12.7109L27.3878 14.3384C28.2035 15.2955 28.2036 16.7034 27.388 17.6605L26.0012 19.2882C25.6517 19.6984 25.4399 20.2085 25.3971 20.7457L25.2265 22.8778C25.1265 24.1313 24.1324 25.1269 22.8788 25.227L20.746 25.3971C20.2089 25.44 19.6991 25.6508 19.289 26.0003L17.6614 27.3876C16.7043 28.2033 15.2956 28.2034 14.3385 27.3878L12.7109 26.0004C12.3007 25.6509 11.791 25.4397 11.2538 25.3969L9.12103 25.227C7.86752 25.1269 6.8734 24.1315 6.77337 22.878L6.6028 20.7458C6.55994 20.2087 6.34815 19.6989 5.99863 19.2888L4.61192 17.6606C3.79628 16.7035 3.7959 15.296 4.61153 14.3389L5.9995 12.7107C6.34902 12.3005 6.55877 11.7908 6.60163 11.2537L6.7722 9.12149C6.87223 7.86798 7.86922 6.87256 9.12273 6.77253L11.2532 6.60261C11.7903 6.55974 12.3003 6.34861 12.7105 5.99909L14.3386 4.61172C15.2957 3.79609 16.7043 3.79609 17.6614 4.61172Z"
                stroke="#24B33B" stroke-width="1.85185" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        }@else {
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path
                d="M16 11.2663V16.5996M9.77565 5.25L5.25 9.77565L5.24589 9.7795C4.78743 10.238 4.55765 10.4677 4.39323 10.7361C4.24702 10.9746 4.139 11.2351 4.07368 11.5072C4 11.8141 4 12.1401 4 12.7923V19.2077C4 19.8599 4 20.186 4.07368 20.4929C4.139 20.765 4.24702 21.0251 4.39323 21.2637C4.55809 21.5327 4.78909 21.7637 5.25 22.2246L9.77564 26.7503C10.2368 27.2115 10.4669 27.4416 10.7361 27.6065C10.9746 27.7527 11.2344 27.8611 11.5065 27.9264C11.8129 28 12.1384 28 12.7887 28H19.21C19.8603 28 20.1871 28 20.4936 27.9264C20.7657 27.8611 21.0257 27.7527 21.2643 27.6065C21.5334 27.4416 21.7641 27.2114 22.2253 26.7502L26.7509 22.2246C27.2121 21.7634 27.4422 21.5328 27.6071 21.2637C27.7533 21.0251 27.8605 20.765 27.9258 20.4929C27.9995 20.186 28 19.8599 28 19.2077V12.7923C28 12.1401 27.9995 11.8141 27.9258 11.5072C27.8605 11.2351 27.7533 10.9747 27.6071 10.7361C27.4422 10.467 27.2121 10.2368 26.7509 9.77564L22.2253 5.25C21.7643 4.78909 21.5333 4.55809 21.2643 4.39323C21.0257 4.24702 20.7657 4.139 20.4936 4.07368C20.1867 4 19.8593 4 19.207 4H12.7917C12.1394 4 11.8134 4 11.5065 4.07368C11.2344 4.139 10.9746 4.24702 10.7361 4.39323C10.4694 4.55664 10.2414 4.78462 9.78853 5.23751L9.77565 5.25ZM16.0664 20.5996V20.7329L15.9336 20.7332V20.5996H16.0664Z"
                stroke="#FF001F" stroke-width="1.85185" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        }
    </div>
    <div class="flex-1 flex flex-col gap-2">
        <h2 class="text-neutral-700 text-16 font-medium">{{toast.title}}</h2>
        <p class="text-neutral-600 text-13 font-normal">{{toast.message}}</p>
    </div>
    <div class="w-[32px]" >
        <svg class="cursor-pointer" (click)="closeToast(toast)" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path d="M21.3323 21.3346L15.999 16.0013M15.999 16.0013L10.6656 10.668M15.999 16.0013L21.3323 10.668M15.999 16.0013L10.6656 21.3347" stroke="#242424" stroke-width="1.85185" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
    </div>
</div>
}
</div>