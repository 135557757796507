import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { ExternalService } from './shared/api/external.service';
import { AuthService } from './shared/services/auth.service';
import { UserApiService } from './user/services/user-api.service';
import { ToastComponent } from "./shared/components/toast/toast.component";
import { CurrencyService } from './shared/services/currency.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, SpinnerComponent, ToastComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'expertly-web';
  constructor(private authService: AuthService, private userService: UserApiService, private externalService: ExternalService, private currencyService: CurrencyService){
    externalService.marketplaceData().subscribe(res=>{
     document.documentElement.style.setProperty('--brand-accent-color', res.accentColor || '');
     document.documentElement.style.setProperty('--brand-text-color', res.textColor || '');
     this.currencyService.setCurrency(res.currencyCode)
    })
    
  }
  ngOnInit(){
    if (this.authService.isBuyer()) {
      this.userService.userInfo().subscribe(res=>{
        if(res.currencyCode)
        this.currencyService.setBuyerCurrency(res.currencyCode)
      })
      this.userService.getBookmark().subscribe((res) => {
        this.userService.bookmarkedProducts.next(res.map((prod) => prod.productId)) 
      });
    }else{
      const savedCurr = localStorage.getItem('CURRENCY');
      if(savedCurr){
        this.currencyService.setBuyerCurrency(savedCurr)
      }
    }
  }
  // loadGoogleAnalytics(trackingID: string): void {
  //   let gaScript = document.createElement('script');
  //   gaScript.setAttribute('async', 'true');
  //   gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${ trackingID }`);

  //   let gaScript2 = document.createElement('script');
  //   gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${ trackingID }\');`;

  //   document.documentElement.firstChild?.appendChild(gaScript);
  //   document.documentElement.firstChild?.appendChild(gaScript2);
  // }
}
