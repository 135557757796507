import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastI, ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss'
})
export class ToastComponent {
  constructor(private toastService: ToastService){
    toastService.state().subscribe(res=>{
      this.showToast(res)
    })
  }

  toasts: ToastI[] = [];

  showToast(toast:ToastI) {
    this.toasts.push(toast);

    setTimeout(() => {
      this.closeToast(toast);
    }, 5000);
  }
  closeToast(toast: ToastI) {
    toast.show = false;
    setTimeout(() => {
      const index = this.toasts.indexOf(toast);
      this.toasts.splice(index, 1);
    }, 300);
  }
}
