import { Routes } from '@angular/router';
import { adminAuthGuard } from './shared/guards/admin-auth.guard';
import { providerAuthGuard } from './shared/guards/provider-auth.guard';
import { ProductPreviewComponent } from './shared/product-preview/product-preview.component';
import { DeepLinkComponent } from './shared/components/deep-link/deep-link.component';
import { agencyAuthGuard } from './shared/guards/agency-auth.guard';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';

export const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [adminAuthGuard],
  },
  {
    path: 'agency',
    loadChildren: () =>
      import('./agency/agency.module').then((m) => m.AgencyModule),
    canActivate: [agencyAuthGuard] 
  },
  {
    path: 'provider',
    loadChildren: () =>
      import('./provider/provider.module').then((m) => m.ProviderModule),
    canActivate: [providerAuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule)
  },
  {
    path:'order/details/:id',
    component: DeepLinkComponent
  },
  {
    path:'product/details/:id',
    component: DeepLinkComponent
  },
  { path: 'product-preview/:id', component: ProductPreviewComponent },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' },
];
