import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  filter,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import { AuthService } from '../services/auth.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing$ = new BehaviorSubject<boolean>(false);
  constructor(private authService: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.includes('/auth') || req.url.includes('/external')) {
      req = this.addTokenHeader(req, {
        Authorization: environment.BASE_TOKEN,
        'x-timezone-offset': this.getTimeDiff(),
        'x-host': location.hostname
      });
    }else if(req.url.includes('/admin')){
      req = this.addTokenHeader(req,{
        Authorization: this.authService.getTokens().accessToken,
        'x-timezone-offset': this.getTimeDiff(),
        'x-host': location.hostname
      })
    }else if(req.url.includes('/provider')){
      req = this.addTokenHeader(req,{
        Authorization: this.authService.getTokens().accessToken,
        'x-timezone-offset': this.getTimeDiff(),
        'x-host': location.hostname
      })
    }else if(req.url.includes('/buyer')){
      req = this.addTokenHeader(req,{
        Authorization: this.authService.getTokens().accessToken,
        'x-timezone-offset': this.getTimeDiff(),
        'x-host': location.hostname
      })
    }else if(req.url.includes('/agency')){
      req = this.addTokenHeader(req,{
        Authorization: this.authService.getTokens().accessToken,
        'x-timezone-offset': this.getTimeDiff(),
        'x-host': location.hostname
      })
    }
    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401 && !req.url.includes('/auth')) {
          return this.handle401Error(req, next);
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing$.getValue()) {
      this.isRefreshing$.next(true);
      return this.authService.refreshToken().pipe(
        tap((response) => {
          this.isRefreshing$.next(false);
          this.authService.setTokens(response);
        }),
        switchMap(({ accessToken }) => {
          return next.handle(
            this.addTokenHeader(request, { Authorization: accessToken })
          );
        }),
        catchError((err) => {
          this.isRefreshing$.next(false);
          this.authService.onError();
          return throwError(() => new Error(err));
        })
      );
    }

    /**
     * If we're already refreshing a token, wait
     * until we get the new one and perform the
     * request with the new Access Token.
     */

    return this.isRefreshing$.pipe(
      filter((is) => !is),
      take(1),
      switchMap(() => {
        const accessToken = this.authService.getTokens().accessToken;
        return next.handle(
          this.addTokenHeader(request, { Authorization: accessToken || '' })
        );
      })
    );
  }

  private addTokenHeader(
    request: HttpRequest<any>,
    headers: { [name: string]: string | string[] }
  ) {
    return request.clone({
      setHeaders: headers,
    });
  }

  private getTimeDiff(): string {
    const currentTime = new Date();
    const timeDiff = (currentTime.getTimezoneOffset() * -1) / 60;
    const timezone = localStorage.getItem('timezone')
    if(timezone) return timezone
    return timeDiff.toString();
  }
}
// export const httpInterceptorProviders: Provider = [
//   { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
// ];