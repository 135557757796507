import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Route, Router } from '@angular/router';

@Component({
  selector: 'app-deep-link',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './deep-link.component.html',
  styleUrl: './deep-link.component.scss'
})
export class DeepLinkComponent {
  constructor(private authService: AuthService, private activatedRoute: ActivatedRoute, private router: Router){}
  ngOnInit(){
    this.activatedRoute.params.subscribe((params) => {
      const id = params['id']
      if (!id) {
        this.router.navigate(["/"])
        return;
      }
      if(this.router.url.includes('/order')){
        this.handleOrderRoles(id)
      }else if(this.router.url.includes('/product')){
        this.handleProductRoles(id)
      }else{
        this.router.navigate(["/"])
      }
    })
  }
  handleOrderRoles(id: string){
    if(this.authService.isAdmin()){
      this.router.navigate([`/admin/order/${id}`])
    }else if(this.authService.isProvider()){
      this.router.navigate([`/provider/order/${id}`])
    }else if(this.authService.isAgency()){
      this.router.navigate([`/agency/order/${id}`])
    }else if(this.authService.isBuyer()){
      this.router.navigate([`/order/${id}`])
    }else{
      localStorage.setItem("ulrToRedirect", `/order/details/${id}`)
      this.router.navigate([`login`])
    }
  }
  handleProductRoles(id: string){
    if(this.authService.isAdmin()){
      this.router.navigate([`/admin`])
    }else if(this.authService.isProvider()){
      this.router.navigate([`/provider/product/${id}`])
    }else if(this.authService.isBuyer()){
      this.router.navigate([`/product/${id}`])
    }else{
      localStorage.setItem("ulrToRedirect", `/product/details/${id}`)
      this.router.navigate([`login`])
    }
  }
}
