import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';

export const providerAuthGuard: CanActivateFn = (route, state) => {
  const router: Router = inject(Router);

  const isLogged = inject(AuthService).isProvider();

  if (state.url == '/provider/login' || state.url.includes('register') ) {
    return true;
  }

  if (!isLogged) {
    localStorage.setItem("ulrToRedirect", state.url)
    router.navigate(['/provider/login']);
  }

  return isLogged;
};
