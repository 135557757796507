import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderService } from '../../services/loader.service';
@Component({
  selector: 'app-spinner',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule],
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.scss',
})
export class SpinnerComponent {
  constructor(public loader: LoaderService) {}
  isLoading = false;
  ngOnInit() {
    this.loader.getLoading().subscribe((res) => {
      setTimeout(() => {
        this.isLoading = res;
      }, 0);
    });
  }
}
